'use strict'

###*
 # @ngdoc service
 # @name ReportConfigurationManager.factory:ReportConfigurationManager

 # @description

###
angular
  .module 'mundoReporting'
  .factory 'ReportsManager', [
    '$q'
    'Restangular'
    '$http'
    'mundoConfiguration'
    (
      $q
      Restangular
      $http
      mundoConfiguration
    ) ->
      managerBase = {}


      managerBase.getReportConfiguration = (configid,shareid, accessToken)->
        $http.defaults.headers.common['Authorization'] = "Bearer " + accessToken
        $http({
          url: "#{mundoConfiguration.oauth.baseUrl}/api/open/services/reports/#{configid}/shares/#{shareid}"
          method: 'GET'
        })

      managerBase.getReportSource = (configid,shareid, accessToken)->
        $http.defaults.headers.common['Authorization'] = "Bearer " + accessToken
        $http({
          url: "#{mundoConfiguration.oauth.baseUrl}/api/open/services/reports/#{configid}/shares/#{shareid}/source"
          method: 'GET'
        })

      managerBase.getReportVisualizers = (configid,shareid, accessToken, parameters)->
        $http.defaults.headers.common['Authorization'] = "Bearer " + accessToken
        $http({
          url: "#{mundoConfiguration.oauth.baseUrl}/api/open/services/reports/#{configid}/shares/#{shareid}/visualize"
          method: 'GET'
          params: parameters
        })

      return managerBase
  ]
